import { Route, Routes } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import NavBar from "./AppBar/NavBar";
import Resume from "./Resume/Resume";

function App() {
  return (
    <>
      <CssBaseline />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="resume" element={<Resume />} />
      </Routes>
    </>
  );
}

function Home() {
  return (
    <div>Home</div>
  )
}



export default App;
