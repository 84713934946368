import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDYXzz2z1yC_3zLJ7OQpqInlZzphCF9LLg",
    authDomain: "albertwang-dev.firebaseapp.com",
    databaseURL: "https://albertwang-dev.firebaseio.com",
    projectId: "albertwang-dev",
    storageBucket: "albertwang-dev.appspot.com",
    messagingSenderId: "173918701239",
    appId: "1:173918701239:web:09402b14eb691e11d1af32",
    measurementId: "G-0BCW2F7XTR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };